import React from 'react';

import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';
import { ThankYou } from '~modules/thank-you';

const seo = {
  seo_title: 'Your account has been created. Happy learning.',
  seo_description:
    'Aleph Beta is committed to the relevance of Jewish learning. We believe Torah study should be evidence-based, intellectually stimulating, emotionally gripping and relevant to your everyday life.'
};

const ThankYouPage = () => (
  <Page>
    <SEO {...seo} />

    <ThankYou />
  </Page>
);

export default ThankYouPage;
