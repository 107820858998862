import React, { useEffect } from 'react';
import { Link as GatsbyLink, navigate } from 'gatsby';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { getPreviousLocationData } from '~utils/previous-path-cookie';
import Container from '~components/Container';
import { lineFix } from '~modules/thank-you/thank-you.utils';
import BackgroundImg from './images/flow-background.svg';

const ThankYou = () => {
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('xs'));

  const { pathname, state } = getPreviousLocationData();

  useEffect(() => {
    setTimeout(() => {
      navigate(pathname, { state });
    }, 5000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container center size={100} background={`url(${BackgroundImg})`} maxWidth="md">
      <Box p={1} mb={2}>
        <Typography variant={isSmallerThanSm ? 'h4' : 'h3'} align="center">
          {lineFix(`Your account has been created. Happy learning!`)}
        </Typography>
      </Box>

      <Box p={1} mb={3}>
        <Typography variant="h6" align="center">
          {lineFix(`Hold on! We are redirecting you back to where you left off in 5 seconds.`)}
        </Typography>
      </Box>

      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textSecondary" align="center">
            {lineFix(`or check these pages:`)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            component={GatsbyLink}
            to="/"
            replace // Add replace to the link in order to prevent the user from navigating the thank you page
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThankYou;
